const get_started = {
    page_title: 'Começar',
    title: 'Algo para explorar para ajudá-lo a ter sucesso',
    subtitle: 'Algumas coisas que você pode fazer para obter rapidamente valor do Logto',
    develop: {
        title: 'Desenvolva: Dedique 5 minutos para integrar o seu aplicativo',
        title_cloud: 'Desenvolver: Integre com segurança seu aplicativo em minutos',
        subtitle_cloud: 'Ou leve 5 minutos para integrar seu aplicativo com nossos SDKs pré-construídos e tutoriais.',
    },
    customize: {
        title: 'Personalize: Ofereça uma ótima experiência de login',
        preview: {
            title: 'Verifique a visualização ao vivo da experiência de login que você acabou de personalizar',
            subtitle: 'Experimente agora a experiência de login do Logto para ver como ela funciona',
        },
        connector: {
            title: 'Adicione mais conectores para oferecer mais métodos de login social',
            subtitle: 'Experimente o login sem senha e habilite uma experiência segura e sem atritos para seus clientes',
        },
        continue_customizing: 'Continuar personalizando',
        try_now: 'Experimente agora',
        add_more: 'Adicionar mais',
    },
    secure: {
        title: 'Segurança: Proteja seus recursos',
    },
    manage: {
        title: 'Gerencie: Defina o controle de acesso para seu produto e usuários',
        rbac: {
            title: 'Adicione controle de acesso baseado em funções para proteger seus recursos',
            subtitle: 'Controle seus recursos por meio de autorização baseada em função escalável para diversos casos de uso.',
        },
        create_roles: 'Criar funções',
    },
    view_all: 'Ver todos →',
};
export default Object.freeze(get_started);
