const menu = {
    profile: 'Perfil',
    language: 'Idioma',
    appearance: {
        label: 'Aparência',
        light: 'Modo claro',
        dark: 'Modo escuro',
        system: 'Sincronizar com o sistema',
    },
    sign_out: 'Sair',
};
export default Object.freeze(menu);
