const verification_code = {
    phone_email_empty: 'Tanto o telefone quanto o e-mail estão vazios.',
    not_found: 'Código de verificação não encontrado. Por favor, envie um código de verificação primeiro.',
    phone_mismatch: 'Telefone não corresponde. Por favor, solicite um novo código de verificação.',
    email_mismatch: 'E-mail não corresponde. Por favor, solicite um novo código de verificação.',
    code_mismatch: 'Código de verificação inválido.',
    expired: 'O código de verificação expirou. Por favor, solicite um novo código de verificação.',
    exceed_max_try: 'Excedido o limite de tentativas de código de verificação. Por favor, solicite um novo código de verificação.',
};
export default Object.freeze(verification_code);
