const dashboard = {
    page_title: 'Painel',
    title: 'Painel',
    description: 'Obtenha uma visão geral sobre o desempenho do seu aplicativo',
    total_users: 'Total de usuários',
    total_users_tip: 'Total de usuários',
    new_users_today: 'Novos usuários hoje',
    new_users_today_tip: 'O número de novos usuários registrados em seus aplicativos hoje',
    new_users_7_days: 'Novos usuários nos últimos 7 dias',
    new_users_7_days_tip: 'O número de novos usuários registrados em seus aplicativos nos últimos 7 dias',
    daily_active_users: 'Usuários ativos diariamente',
    daily_active_users_tip: 'O número de tokens trocados por usuários únicos em seus aplicativos hoje',
    weekly_active_users: 'Usuários ativos semanalmente',
    weekly_active_users_tip: 'O número de tokens trocados por usuários únicos em seus aplicativos nos últimos 7 dias',
    monthly_active_users: 'Usuários ativos mensais',
    monthly_active_users_tip: 'O número de tokens trocados por usuários únicos em seus aplicativos nos últimos 30 dias',
};
export default Object.freeze(dashboard);
