const roles = {
    page_title: 'Títulos das funções',
    title: 'Funções',
    subtitle: 'As funções incluem permissões que determinam o que um usuário pode fazer. O RBAC usa funções para dar aos usuários acesso a recursos para ações específicas.',
    create: 'Criar função',
    role_name: 'Nome da função',
    role_type: 'Tipo de função',
    type_user: 'Usuário',
    type_machine_to_machine: 'Máquina para máquina',
    role_description: 'Descrição',
    role_name_placeholder: 'Insira o nome da sua função',
    role_description_placeholder: 'Insira a descrição da sua função',
    col_roles: 'Funções',
    col_type: 'Tipo',
    col_description: 'Descrição',
    col_assigned_entities: 'Atribuído',
    user_counts: '{{count}} usuários',
    application_counts: '{{count}} aplicativos',
    user_count: '{{count}} usuário',
    application_count: '{{count}} aplicativo',
    assign_permissions: 'Atribuir permissões',
    create_role_title: 'Criar função',
    create_role_description: 'Use funções para organizar permissões e atribuí-las a usuários.',
    create_role_button: 'Criar função',
    role_created: 'A função {{name}} foi criada com sucesso.',
    search: 'Pesquisar pelo nome, descrição ou ID da função',
    placeholder_title: 'Funções',
    placeholder_description: 'As funções são um agrupamento de permissões que podem ser atribuídas a usuários. Certifique-se de adicionar as permissões antes de criar funções.',
    assign_roles: 'Atribuir funções',
    management_api_access_notification: 'Para acessar a API de gerenciamento do Logto, selecione funções com permissões de API de gerenciamento <flag/>.',
    with_management_api_access_tip: 'Esta função de máquina para máquina inclui permissões para a API de gerenciamento do Logto',
    role_creation_hint: 'Não encontrou a função certa? <a>Crie uma função</a>',
};
export default Object.freeze(roles);
