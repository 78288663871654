const invitation = {
    find_your_tenants: 'Encontre seus inquilinos',
    find_tenants_description: 'Seu endereço de e-mail pode já estar registrado em vários inquilinos. Você pode escolher se juntar aos existentes ou continuar e criar um novo.',
    create_new_tenant: 'Criar um novo inquilino',
    email_not_match_title: 'Você está atualmente conectado como\n{{email}}',
    email_not_match_description: 'Por favor, faça login com a conta correta para aceitar o convite e se tornar um membro da organização.',
    switch_account: 'Entrar com outra conta',
    invalid_invitation_status: 'Convite inválido. Entre em contato com o administrador e tente novamente.',
    invitation_not_found: 'Convite não encontrado. Por favor, entre em contato com o administrador.',
};
export default Object.freeze(invitation);
