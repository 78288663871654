const log_details = {
    page_title: 'Detalhes do registro de auditoria',
    back_to_logs: 'Voltar para registros',
    back_to: 'Voltar para {{name}}',
    success: 'Sucesso',
    failed: 'Falhou',
    event_key: 'Chave do evento',
    application: 'Aplicativo',
    ip_address: 'Endereço de IP',
    user: 'Usuário',
    log_id: 'ID do registro',
    time: 'Tempo',
    user_agent: 'Agente do usuário',
    tab_details: 'Detalhes',
    raw_data: 'Dados completos',
};
export default Object.freeze(log_details);
